import logo from "./assets/rainwords-cover.png";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/user-data-deletion">
            <DeleteUserData />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return (
    <div>
      <img src={logo} alt="cover" />
    </div>
  );
}

function DeleteUserData() {
  return (
    <div style={{ textAlign: "center" }}>
      <p>
        <b>RainWords</b>
      </p>
      <p>
        <b>Accessing and Deleting Personal Information Held by Us</b>
      </p>
      <p>
        You can request a copy of or deletion of your app account data by
        sending an email to info@rainwords.fun. For deletion requests, we will
        take reasonable measures to delete your personal information from our
        records. If you have sent content through or posted content on the
        Service, we may not be able to delete it. We will keep certain records
        (e.g., personal information relating to payments or customer service
        matters) where we need (and have rights) to, such as for legal or
        accounting purposes. We’ll also keep information in order to exercise,
        defend, or establish our rights. If you have any problems with the
        websites, an app or any of our services, please Contact Us.
      </p>
    </div>
  );
}

function Privacy() {
  return (
    <div style={{ textAlign: "center" }}>
      <p>
        <b>Privacy Policy RainWords</b>
      </p>
      <p>
        <p>
          <b>General</b>
        </p>
        This policy describes how RainWords and, its other group companies
        (collectively described as “we”, “us” or “our”) and its third-party
        marketing and advertising partners collect, use, process and share
        information about you. This Privacy Policy applies whenever you use our
        “Services,” which means our apps, products, services, content, our
        websites, such as rainwords.fun and others, and whether such Services
        are received through your mobile device, one of our website or on other
        platforms such as Facebook. It describes how we collect, store, use, and
        disclose your personal information when providing these Services to you,
        and your rights and choices concerning your information. By downloading,
        accessing and/or using our app, or interacting with our websites or
        other Services, you agree to the collection and use of your information
        in accordance with this Privacy Policy. If you do not want us to
        collect, store, use, or share your information in the ways described in
        this Privacy Policy, you may not use our app or use our other Services.
        We’ll collect and store any information that you provide to us,
        including when you create your account or a user profile.
      </p>
      <p>
        <b>Information Shared With Us Directly</b>
      </p>
      <p>
        When you use our Services, you may give us information directly (like
        when you’re setting up your account), and we’ll store that information
        on our systems and use it for the purposes described in this Privacy
        Policy. Some parts of our Services may use a more traditional
        registration or account set-up process where you may be asked to give us
        information like: your first and last name; your username; your gender;
        your age or birthday; your e-mail address; a password; and/or other
        information that helps us make sure it’s you accessing your account or
        helps us improve our Services. We may also let you create a user profile
        that other users can see. Your user profile may include information
        like: your first and last name; your app username; your gender; your
        biographic details (like your age or age range); the approximate
        physical location information that you provide; and/or a user ID that is
        created by us and used to identify your app account. When you use our
        Services, we’ll collect information about the ways in which you use and
        interact with our Services, such as when and for how long you use our
        app, use the different app features and the other users you interact
        with, purchases you make, progress or levels you achieve, and other
        in-app activities. If you choose to use communication features in our
        Services that allow you to communicate or share information with other
        users, then we’ll collect and store such information. This includes:
        your participation in user chats and message boards; your public posts
        or comments on other users’ profiles or boards; your non-public messages
        or invitations (including gifts and requests) to other users. This is
        collected and stored either directly through our websites or through
        in-app mechanisms; your chats with other users (whether by instant
        message or live chat); and/or your posted photos, drawings, or other
        user-generated content. We may access in real-time, record, and/or store
        archives of these communications, comments, photos, drawings, RainWordss
        and other user-generated content on our servers to protect the safety
        and well-being and ensure a safe and appropriate environment for our
        users; to protect our rights and property in connection with our
        Services; to conduct research; to operate, improve, personalize, and
        optimize our Services and our users’ experiences, including through the
        use of analytics; and to manage and deliver advertising. Where required
        by law, we will seek your consent for this. If you purchase in-app
        virtual currency or items in our app, our third-party payment processor
        will collect the billing and financial information it needs to process
        your charges. This may include your postal address, e-mail address, and
        financial information. Our payment processors do not share your
        financial information, like credit card numbers, with us but they may
        share non-financial information related to your purchases, like your
        name, billing address, and the items purchased. When you ask for help
        from our Customer Support team, we will collect and store the contact
        information you give them (generally, your name and e-mail address),
        information about your app progress or activity on our Services, and
        your User ID number. We will also store the communications you have with
        our Customer Service team and any additional information in those
        communications in order to provide support and improve the Services. For
        example, we may collect and store some or all of the following
        information from the provider of the connected third-party application:
        your first and last name; your profile picture or its URL; your user
        identification number (like your Facebook ID number), which may be
        linked to publicly-available information like your name and profile
        photo; your friends’ user ID numbers and other public data; the e-mail
        address you provided to that third-party application; your approximate
        physical location and that of the devices you use to access our
        Services; your gender; your birthday, age, and/or age range; information
        about your activities on or through the connected third-party
        application; other publicly-available information on the third-party
        application; and/or any other information that you or the provider of
        the third-party application share with us. If you access our Services
        from a third-party application or connect our Services to a third-party
        application, you should also read that third-party application’s terms
        of service and privacy policy. If you are unclear about what information
        a third-party application is sharing with us, please go to the
        third-party application to find out more about their privacy practices.
      </p>
      <p>
        <b>Cookies</b>
      </p>
      <p>
        We, our service providers, and our business partners use these cookies
        and other similar technologies to collect and analyze certain kinds of
        technical information, including: IP address; the type of computer or
        mobile device you are using; platform type (like Apple iOS or Android);
        your operating system version; your mobile device’s identifiers, like
        Android Advertising ID (AAID); application performance and de-bugging
        information; your browser type and language; referring and exit pages,
        and URLs; the number of clicks on an app feature or web page; the amount
        of time spent on an app feature or web page; domain names; landing
        pages; pages viewed and the order of those pages; and/or app state and
        the date and time of activity on our Services. In some cases, we will
        cross-reference the above information with your User ID. If you use our
        app on a mobile device, in addition to your device identifiers
        (described above), we may also collect: the name you have associated
        with your device; your country; your telephone number (if you provide
        it); your specific geolocation (with your permission); your mobile
        contacts (with your permission); and/or information about the games and
        other third-party apps you have on your device.
      </p>
      <p>
        <b>Purposes of collecting information</b>
      </p>
      <p>
        The main use of the information we collect and store is to provide you
        with the Services you request from us and to improve your app
        experience, but there are other uses as well, such as: to improve and
        optimize our Services and our users’ experiences; to create your app
        accounts and allow you to use our features; to identify and suggest
        connections with other users and personalize our Services to you; to
        communicate with you about the Services you’re using, including in-app
        updates, new Services, and promotional offers that we think might be of
        interest to you; to enable users to communicate with each other; to
        provide technical support and respond to user inquiries; to protect the
        safety and well-being of our users; to protect our rights and property
        in connection with our Services; to prevent fraud or potentially illegal
        activities, and to enforce our Terms of Service; to manage and deliver
        contextual and behavioral advertising; to administer rewards, surveys,
        sweepstakes, contests, or other promotional activities or events
        sponsored or managed by us or our business partners; to comply with our
        legal obligations, resolve any disputes we may have with you or other
        users, and to enforce our agreements with third parties; and/or to
        conduct research.
      </p>
      <p>
        <b>Accessing and Deleting Personal Information Held by Us</b>
      </p>
      <p>
        You can request a copy of or deletion of your app account data by
        sending an email to info@rainwords.fun. For deletion requests, we will
        take reasonable measures to delete your personal information from our
        records. If you have sent content through or posted content on the
        Service, we may not be able to delete it. We will keep certain records
        (e.g., personal information relating to payments or customer service
        matters) where we need (and have rights) to, such as for legal or
        accounting purposes. We’ll also keep information in order to exercise,
        defend, or establish our rights. If you have any problems with the
        websites, an app or any of our services, please Contact Us
      </p>
      <p>
        {" "}
        <b>Advertising</b>
      </p>
      <p>
        We use advertising to enable us to offer our app, features, and other
        Services for free. These advertisements may include, without limitation,
        banner ads, full-screen ads, or “offer wall” (offers made by a third
        party in exchange for in-app benefits). To clarify, proposals on the
        “offer wall” are made by a third party, not by us, and therefore we are
        not responsible for the content or services provided by such third
        parties. We do not actively share personal information that directly
        identifies you with third-party advertisers for their direct marketing
        purposes unless you give us your consent. To enable advertisers, ad
        networks, and “offer wall” providers to serve you with more relevant
        ads, they may collect, or we may collect and share with them, the
        following types of information when you use our Services: performance
        data (like the number of clicks on an advertisement); certain technical
        information (like IP address and de-identified persistent device
        identifier such as a hashed Android ID); a unique identifier, such as
        your User ID; and/or other contextual data about your app progress (like
        your level and session length). In addition to serving you with relevant
        ads, the information collected may be used to: measure how effective ads
        are; offer you targeted advertising (both on and off our Services) in
        order to personalize your experience by showing you advertisements for
        products and services that are more likely to appeal to you (a practice
        known as interest-based advertising or behavioral advertising); and/or
        undertake web analytics to analyze traffic and other user activity to
        improve your experience. Advertisers or ad networks may collect this
        information through the use of cookies and other similar tracking
        technologies, and they may use a single tracking technology or multiple
        tracking technologies at the same time. Please note that we might send
        you “push notifications” as well as other notifications and messages to
        the extent you permit us to do so. If you wish to stop receiving such
        notifications, you may opt-out through your device settings. Other
        manners of notifications, advertisements and newsletters sent by us will
        include customary opt-out mechanisms.
      </p>
      <p>
        <b>Promotions and Competitions</b>
      </p>
      <p>
        From time to time we may run promotional campaigns or competitions
        during which we may collect certain personal information from you, such
        as your name, address, post code, email address or other information or
        content submitted in order for you to claim a prize. Use of such
        information shall be made solely in connection with such campaign or
        competition. Other terms may apply. We may cross-reference information
        may also be combined with your other account information or other
        information you provided to us which will be processed, stored and
        shared in accordance with this Privacy Policy. If you have any problems
        with the website, app or any of our services, please contact through our
        Email
      </p>
    </div>
  );
}
