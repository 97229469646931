import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDd_99WrzqjRxUKa_yynPIN7ecnoheYgTI",
//   authDomain: "typemaster-30b1c.firebaseapp.com",
//   projectId: "typemaster-30b1c",
//   storageBucket: "typemaster-30b1c.appspot.com",
//   messagingSenderId: "172007377768",
//   appId: "1:172007377768:web:ab574576093570140c24dd",
//   measurementId: "G-R0LRRPTVTR",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
